<template>

<router-link class="product-image-link" :to="detailLink">
	<img class="thumbnail-image" :alt="product.manufacturer + ' ' + product.model + ' ' + product.caliber + ' Image'" :src="product.image_src" @click="featuredProductClick()" >
	<div v-if="product.display_flag" class='display_flag'><img :src="product.display_flag" alt='Display Flag'></div>
</router-link>

</template>

<script>
export default {
	name: 'product-image-link',
	props: ['product', 'featured'],
	data() {
		return {
			querySource: ''
		}
	},
	created() {
		if (this.$route.query.link_from) {
			this.querySource = '?link_from=' + this.$route.query.link_from // allows us to pass on source, e.g. mc for mailchimp
		}
	},
	computed: {
		detailLink() {
			if (this.product.product_type_code == 'CLASSES' && this.product.class_detail_id) {
				return '/classesdetail/' + this.product.link_name
			} else {
				return '/product/' + this.product.link_name + this.querySource
			}
		}
	},
	methods: {
		featuredProductClick() {
			if (this.featured) {
				this.apiCall({ destination: 'feature_product_click', data: { product_id: this.product.product_id } })
			}
		}
	}
}
</script>
