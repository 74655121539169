<template>

<div class="columns columns-2 product-controls">
	<router-link class="button primary product-detail-link" :to="detailLink"><span @click="featuredProductClick()">Details</span></router-link>
	<template v-if="product.product_type_code != 'CLASSES'">
		<button v-if="!product.price_set.customer_price || product.price_set.available < 1" class="button disabled not-available">Unavailable</button>
		<router-link v-else-if="product.price_set.customer_price && in_cart" to="/cart" class="button action go-to-cart">View Cart</router-link>
		<div v-else-if="product.call_to_order" class="button primary" @click="$router.push('/contact')">Call To Order</div>
		<div v-else-if="product.price_set.customer_price && !in_cart && product.price_set.available > 0" :class="['button', addingToCart ? 'disabled' : 'action', 'add-to-cart']" @click="addToCart()">Add to Cart</div>
	</template>
</div>

</template>

<script>
export default {
	name: 'product-controls',
	props: ['product', 'featured'],
	data() {
		return {
			addingToCart: false,
			in_cart: false,
			querySource: '',
			message: ''
		}
	},
	created() {
		this.in_cart = (this.product.in_cart ? true : false)
		if (this.$route.query.link_from) {
			this.querySource = '?link_from=' + this.$route.query.link_from // allows us to pass on source, e.g. mc for mailchimp
		}
	},
	computed: {
		detailLink() {
			if (this.product.product_type_code == 'CLASSES' && this.product.class_detail_id) {
				return '/classesdetail/' + this.product.link_name
			} else {
				return '/product/' + this.product.link_name + this.querySource
			}
		}
	},
	methods: {
		featuredProductClick() {
			if (this.featured) {
				this.apiCall({ destination: 'feature_product_click', data: { product_id: this.product.product_id } })
			}
		},
		addToCart() {
			if (this.addingToCart) {
				return
			}
			this.message = ''
			this.addingToCart = true
			this.apiCall({ destination: 'add_to_cart', data: { product_id: this.product.product_id, quantity: 1 } }).then(obj => {
				if (obj.status == 'success') {
					this.in_cart = true
        			this.fetchShoppingCartCount()
				} else {
					this.message = obj.message
				}
				this.addingToCart = false
            }).catch(error => {
                console.log(error)
            })
		}
	}
}
</script>

<style lang="scss" scoped>
.product-controls {
	display: grid;
}
</style>
