<template>
<div id="app" :class="{ 'no-scroll': (mobileDropdownOpen) }" ref="site_top">
<template v-if="getTemplateData || showNotABot">
	<div v-if="getClient.is_demo" class="in-demo-mode">This is a demo website!  Prices are not accurate and checkout is disabled.</div>
	<div class="section meta" v-if="getTemplateData.siteOptions && getTemplateData.siteOptions.meta.showing" @mouseover="newsAlertsHover = true" @mouseleave="newsAlertsHover = false">
		<ul class="news-ticker">
			<transition-group name="news-alert">
				<li v-for="(alert, index) in newsAlerts" v-if="index == newsAlertsIndex" :key="'alert-' + index">
					<template v-if="alert.link_url">
						<a :href="alert.link_url">{{ alert.content }}</a>
					</template>
					<template v-else>
						{{ alert.content }}
					</template>
				</li>
			</transition-group>
		</ul>
	</div>

	<!-- Desktop Page Menu -->
	<nav v-if="browserIdSet && getTemplateData.siteOptions && getTemplateData.siteOptions.navPage.showing && pagesMenu.length > 0" class="section desktop-page-menu" :class="[ getTemplateData.siteOptions.navPage.alignmentClass ]">
		<div class="content">
			<!-- Code-split menu -->
			<ul class="menu">
				<li v-for="(menuItem, index) in pagesMenu" v-if="index < 6" :key="'desktop-page-menu-item-' + index">
					<router-link v-if="menuItem.link_type != 'external'" :to="menuLink(menuItem)" class="page-menu">{{ menuItem.menu_label }}</router-link>
					<a v-else :href="menuItem.link_value" target="_blank">{{ menuItem.menu_label }}</a>
				</li>
				<li :class="[{ open: pagesDropdownOpen == true }]" @click="pagesDropdownOpen = !pagesDropdownOpen" style="user-select:none;" v-if="pagesMenu.length > 6">More</li>
			</ul>
			<ul class="menu pages-dropdown" v-if="pagesDropdownOpen">
				<li v-for="(menuItem, index) in pagesMenu" v-if="index >= 6" :key="'desktop-page-menu-dropdown-item-' + index">
					<router-link v-if="menuItem.link_type != 'external'" :to="menuLink(menuItem)">{{ menuItem.menu_label }}</router-link>
					<a v-else :href="menuItem.link_value" target="_blank">{{ menuItem.menu_label }}</a>
				</li>
			</ul>
		</div>
	</nav>

	<transition name="slide-in">
		<div v-if="browserIdSet && mobileDropdownOpen" class="mobile-menu-inner">
			<div class="button close" @click="mobileDropdownOpen = null"><i class="fas fa-times"></i></div>
			<nav class="page-menu" v-if="getTemplateData.siteOptions && getTemplateData.siteOptions.navPage.showing && pagesMenu.length > 0">
				<ul>
					<li v-for="(menuItem, index) in pagesMenu" :key="'mobile-page-menu-item-' + index">
						<router-link v-if="menuItem.link_type != 'external'" :to="menuLink(menuItem)">{{ menuItem.menu_label }}</router-link>
						<a v-else :href="menuItem.link_value" target="_blank">{{ menuItem.menu_label }}</a>
					</li>
				</ul>
			</nav>
			<nav class="section" role="main" v-if="productMenu.length > 0 || Object.entries(departmentMenu).length">
				<div class="content">
					<ul>
						<li v-for="(menuItem, index) in productMenu" :key="'mobile-product-menu-item-' + index">
							<router-link v-if="menuItem.link_type != 'external'" :to="menuLink(menuItem)">{{ menuItem.menu_label }}</router-link>
							<a v-else :href="menuItem.link_value" target="_blank">{{ menuItem.menu_label }}</a>
						</li>
						<li v-if="Object.entries(departmentMenu).length" @click="toggleDepartmentDropdown()"><i :class="['fas fa-chevron-down menu_arrow', {menu_open: departmentDropdownOpen || categoryDropdownOpen}]"></i> Browse Departments</li>
					</ul>
					<div class="menu department-dropdown" v-if="departmentDropdownOpen">
						<div v-for="(menuItem, index) in departmentMenu" :key="'desktop-department-menu-item-' + index">
							<div class='description' @click="getCategoryList(menuItem)">{{ menuItem.description }}</div>
						</div>
					</div>
					<div class="menu category-dropdown" v-if="categoryDropdownOpen">
						<div class="department-name" @click="toggleCategoryDropdown()"><i class="fas fa-chevron-left back_arrow"></i>{{ departmentName }}</div>
						<div class="category-dropdown-list">
							<div v-for="(menuItem, index) in categoryMenu" :key="'desktop-category-menu-item-' + index">
								<router-link :to="'/category/' + menuItem.link_name">{{ menuItem.description }}</router-link>
							</div>
						</div>
					</div>
				</div>
			</nav>
		</div>
	</transition>
	<!-- slide-down -->

	<header v-if="getTemplateData.siteOptions" class="section" ref="header" :class="[ getTemplateData.headerOptions.layout ]">
		<div class="content">
			<!-- Mobile -->

			<div id="logo-container">
			  <div :class="['hamburger', { open: mobileDropdownOpen == true }]" @click="mobileDropdownOpen = !mobileDropdownOpen">
				  <div class="layer layer-1"></div>
				  <div class="layer layer-2"></div>
				  <div class="layer layer-3"></div>
			  </div>
				<router-link to="/">
					<img :src="getTemplateData.headerOptions.logo.image_src" class="logo" v-if="getTemplateData.headerOptions && getTemplateData.headerOptions.logo.image_src" alt="Logo Image">
					<h1 v-else>{{ getClient.company_name }}</h1>
				</router-link>
			</div>
			<div v-if="browserIdSet" class="shopping-cart-icon">
				<router-link :to="myAccountLink">My Account</router-link>
				<router-link to="/cart" class="cart-icon" :style="getTemplateData.headerOptions.shoppingCart.style" v-if="getTemplateData.siteOptions">
					<i class="fas fa-shopping-cart"></i>
					<div class="cart-count">{{ getShoppingCartCount }}</div>
				</router-link>
			</div>
			<div v-if="browserIdSet" class="search">
				<span class="input-go" @click="doSearch()"><i class="fas fa-search"></i></span>
				<label for="search-field" style="display: none;">Search</label>
				<input id="search-field" type="text" ref="search_input" v-model="searchTerm" placeholder="Search..." @keyup.enter="doSearch()">
			</div>
		</div>
	</header>

	<header v-else-if="clientLogo" style="padding: 1em; text-align: center;">
		<img :src="clientLogo" class="logo" alt="Logo Image">
	</header>

	<!-- Desktop Product Menu -->
	<nav v-if="browserIdSet && getTemplateData.siteOptions && (productMenu.length > 0 || Object.entries(departmentMenu).length)" class="section desktop-nav" :class="[ getTemplateData.siteOptions.navMain.alignmentClass ]" role="main">
		<div class="content">
			<ul class="menu">
				<li v-if="Object.entries(departmentMenu).length" @click="toggleDepartmentDropdown()"><i :class="['fas fa-chevron-down menu_arrow', {menu_open: departmentDropdownOpen || categoryDropdownOpen}]"></i> Browse</li>
				<li v-for="(menuItem, index) in productMenu" v-if="index < 7" :key="'desktop-product-menu-item-' + index">
					<router-link v-if="menuItem.link_type != 'external'" :to="menuLink(menuItem)">{{ menuItem.menu_label }}</router-link>
					<a v-else :href="menuItem.link_value" target="_blank">{{ menuItem.menu_label }}</a>
				</li>
				<li @click="productDropdownOpen = !productDropdownOpen" style="user-select:none;" v-if="productMenu.length > 7">More</li>
			</ul>
			<ul class="menu product-dropdown" v-if="productDropdownOpen">
				<li v-for="(menuItem, index) in productMenu" v-if="index >= 7" :key="'desktop-product-menu-item-' + index">
					<router-link v-if="menuItem.link_type != 'external'" :to="menuLink(menuItem)">{{ menuItem.menu_label }}</router-link>
					<a v-else :href="menuItem.link_value" target="_blank">{{ menuItem.menu_label }}</a>
				</li>
			</ul>
			<div class="menu department-dropdown" v-if="departmentDropdownOpen">
				<div v-for="(menuItem, index) in departmentMenu" :key="'desktop-department-menu-item-' + index">
					<div class='menu-item' @click="getCategoryList(menuItem)">{{ menuItem.description }}</div>
				</div>
			</div>
			<div class="menu category-dropdown" v-if="categoryDropdownOpen">
				<div class="department-name" @click="toggleCategoryDropdown()"><i class="fas fa-chevron-left back_arrow"></i>{{ departmentName }}</div>
				<div class="category-dropdown-list">
					<div v-for="(menuItem, index) in categoryMenu" :key="'desktop-category-menu-item-' + index">
						<router-link :to="'/category/' + menuItem.link_name">{{ menuItem.description }}</router-link>
					</div>
				</div>
			</div>
		</div>
	</nav>

	<router-view v-if="browserIdSet" :key="$route.fullPath" />

	<footer v-if="browserIdSet && getTemplateData.siteOptions && !showNotABot" class="section columns columns-4">
		<div class="column">
			<ul class="menu desktop-footer-menu">
				<li><router-link to="/contact">Contact Us</router-link></li>
				<li><router-link to="/policies">Store Policies</router-link></li>
				<li><router-link :to="myAccountLink">My Account</router-link></li>
			</ul>
		</div>

		<!-- Footer menu part 1 -->
		<div class="column">
			<ul class="menu desktop-footer-menu" v-if="footerMenu.length > 0">
				<li v-for="(menuItem, index) in footerMenu" v-if="index < 5" :key="'desktop-footer-menu-item-' + index">
					<router-link v-if="menuItem.link_type != 'external'" :to="menuLink(menuItem)">{{ menuItem.menu_label }}</router-link>
					<a v-else :href="menuItem.link_value" target="_blank">{{ menuItem.menu_label }}</a>
				</li>
			</ul>
		</div>

		<!-- Footer menu part deux -->
		<div class="column">
			<ul class="menu desktop-footer-menu" v-if="footerMenu.length > 0">
				<li v-for="(menuItem, index) in footerMenu" v-if="index > 4" :key="'desktop-footer-menu-item-' + index">
					<router-link v-if="menuItem.link_type != 'external'" :to="menuLink(menuItem)">{{ menuItem.menu_label }}</router-link>
					<a v-else :href="menuItem.link_value" target="_blank">{{ menuItem.menu_label }}</a>
				</li>
			</ul>
		</div>

		<div class="column">
			<a :href="massageLink(socialMedia['FACEBOOK'])" v-if="socialMedia['FACEBOOK']" target="_blank"><i class="fab fa-facebook-square"></i><span style="display:none;">Facebook</span></a>
			<a :href="massageLink(socialMedia['TWITTER'])" v-if="socialMedia['TWITTER']" target="_blank"><i class="fab fa-twitter-square"></i><span style="display:none;">Twitter</span></a>
			<a :href="massageLink(socialMedia['PINTREST'])" v-if="socialMedia['PINTREST']" target="_blank"><i class="fab fa-pinterest-square"></i><span style="display:none;">Pinterest</span></a>
			<a :href="massageLink(socialMedia['INSTAGRAM'])" v-if="socialMedia['INSTAGRAM']" target="_blank"><i class="fab fa-instagram"></i><span style="display:none;">Instagram</span></a>
			<a :href="massageLink(socialMedia['YOUTUBE'])" v-if="socialMedia['YOUTUBE']" target="_blank"><i class="fab fa-youtube-square"></i><span style="display:none;">YouTube</span></a>
			<a :href="massageLink(socialMedia['BBB'])" v-if="socialMedia['BBB']" target="_blank"><img class="bbb-seal" src="https://media.shootingsportsmedia.com/templates/bbb-seal.png" style="max-width: 100px;"><span style="display:none;">BBB Accredited</span></a>
			<div class="copyright">
				&copy; {{ $moment().format('YYYY') }} {{ getClient.company_name }}<br>
				<a v-if="!hideLoginLink" href="/dashboard/login?logout=true">Login</a>
			</div>
		</div>

	</footer>

	<div v-if="showPendingPaymentModal" class="modal">
		<div class="inner">
			<h2>Checkout In Progress</h2>
			<div class="content">
				<p>There is a checkout payment transaction in progress.<br>Please go to Checkout to complete...</p>
			</div>
			<div class="controls">
				<div class="button action" @click="goToCheckout()">Go To Checkout</div>
			</div>
		</div>
	</div>

	<div v-if="showAgeVerificationModal" class="modal age-verify-modal">
		<div class="inner">
			<h2 class="button action no-pointer">Please Confirm Your Age</h2>
			<div v-if="websiteMinimumAgeContent" class="content" style="white-space: pre-line;">
				{{ websiteMinimumAgeContent }}
			</div>
			<div v-else class="content">
				<p>Our website features Shooting Sports products. Please confirm that you are {{ websiteMinimumAge }} years old before entering.</p>
			</div>
			<div class="controls">
				<div class="button action" @mouseup="confirmAge(true)">I'm {{ websiteMinimumAge }}+</div>
				<div class="button neutral" @mouseup="confirmAge(false)">Under {{ websiteMinimumAge }}</div>
			</div>
		</div>
	</div>

	<div v-if="ageVerificationNotConfirmed" class="modal age-verify-modal age-nope">
		<div class="inner">
			<h2>Access Denied</h2>
			<div class="content">
				<p>We're sorry, but you are not old enough to access this website. You must be at least {{ websiteMinimumAge }} years old to enter this website.</p>
			</div>
		</div>
	</div>

	<div v-if="showMailchimpPopup" class="modal mailchimp-modal">
		<div class="inner">
			<h2 class="button action no-pointer">Subscribe to Our Mailing List</h2>
			<div class="content">
				<p>May we send you updates from time to time?</p>
				<label>Your Name</label>
				<input type="text" v-model="mailchimpForm.full_name">
				<label>Email Address</label>
				<input type="text" v-model="mailchimpForm.email_address">
				<div v-if="mailchimpExtraText" style="margin: 0.5em 0;">{{ mailchimpExtraText }}</div>
				<div v-if="mailchimpMessage" class="note">{{ mailchimpMessage }}</div>

			</div>
			<div class="controls two-columns">
				<div class="button neutral" @click="subscribeNoThanks()">No Thanks</div>
				<div :class="['button', 'action', { disabled: !mailchimpForm.email_address || (mailchimpRequireName && !mailchimpForm.full_name) }]" @click="subscribe()">Subscribe</div>
			</div>
		</div>
	</div>

</template>
<!-- <loading class="full-page loading-app" v-if="getSiteLoading.length > 0"></loading> -->
</div>
</template>

<script>
import { setOptions, bootstrap } from 'vue-gtag'
export default {
	name: 'App',
	data() {
		return {
			loading: [],
			clientName: '',
			siteDescription: '',
			siteKeywords: '',
			shoppingCartCount: 0,
			mobileDropdownOpen: false,
			pagesDropdownOpen: false,
			productDropdownOpen: false,
			newsAlerts: {},
			newsAlertsHover: false,
			newsAlertsInterval: null,
			newsAlertsIndex: 0,
			pagesMenu: [],
			productMenu: [],
			departmentMenu: {},
			departmentDropdownOpen: false,
			departmentName: '',
			categoryMenu: {},
			categoryDropdownOpen: false,
			footerMenu: [],
			searchTerm: '',
			socialMedia: {
				'FACEBOOK': null,
				'TWITTER': null,
				'PINTREST': null,
				'INSTAGRAM': null,
				'YOUTUBE': null,
				'BBB': null
			},
			showPendingPaymentModal: false,
			websiteMinimumAge: '',
			websiteMinimumAgeContent: '',
			showAgeVerificationModal: false,
			ageVerificationNotConfirmed: false,
			hideLoginLink: false,
			mailchimpDisplayPopup: false,
			mailchimpSubscribeListId: '',
			mailchimpExtraText: '',
			mailchimpRequireName: false,
			mailchimpForm: {},
			mailchimpMessage: '',
			saving: false,
			showMailchimpPopup: false,
			showNotABot: false,
			browserIdSet: false,
			window: {
				width: 0
			},
			clientLogo: ''
		}
	},
	created() {
		if (this.$route.path == '/not-a-bot' || this.$route.path == '/maintenance-mode') {
			this.showNotABot = true
			return
		}
		// slight delay to allow the browserId to be set
		setTimeout(() => {
			// this.updateEntryPath(this.$route.path)
			this.fetchTemplateCSS()
		}, 100);
		window.addEventListener('resize', this.handleResize);
        this.handleResize();
   	},
   	destroyed() {
        window.removeEventListener('resize', this.handleResize);
	},
	mounted() {
		if (!this.showNotABot) {
			if (window.localStorage.getItem('process_payment')) {
				this.showPendingPaymentModal = true
			}
		}
	},
	watch: {
		getUser(newVal, oldVal) {
			if (oldVal.user_type_code == '' && newVal.user_type_code == "CUSTOMER") {
				this.fetchShoppingCartCount()
			}
		},
		'$route' (newVal, oldVal) {
			this.updateOpenDropdown(null)
			if (this.websiteMinimumAge && !this.checkMinimumAgeConfirmed()) {
				if (newVal != '/age-not-verified') {
					this.$router.push('/age-not-verified')
				}
				return
			}
			var titleElement = document.getElementById('website_title')
			if (this.$route.params.link_name) {
				titleElement.text = this.capitalizeWords(this.$route.params.link_name) + ' | ' + this.clientName
				let currentDescription = document.querySelector('meta[name="description"]').getAttribute('content').split(' | ').pop()
				document.querySelector('meta[name="description"]').setAttribute('content', this.capitalizeWords(this.$route.params.link_name) + ' | ' + currentDescription);
				document.querySelector('meta[name="keywords"]').setAttribute('content', this.$route.params.link_name.replaceAll('-', ','));
			} else {
				titleElement.text = 'Home | ' + this.clientName
				document.querySelector('meta[name="description"]').setAttribute('content', this.siteDescription);
				document.querySelector('meta[name="keywords"]').setAttribute('content', this.siteKeywords);
			}
			window.scrollTo({
				top: this.$refs.site_top.offsetTop,
				left: 0
			})
		},
		'$route.query' (value) {
			window.scrollTo({
				top: this.$refs.site_top.offsetTop,
				left: 0
			})
		},
		newsAlerts(value) {
			if (value && Object.keys(value).length == 0) return
			this.newsAlertsInterval = setInterval(() => {
				if (this.newsAlertsHover) return
				if (this.newsAlertsIndex == Object.keys(this.newsAlerts).length - 1) {
					this.newsAlertsIndex = 0
				} else {
					this.newsAlertsIndex++
				}
			}, 5000)
		}
	},
	computed: {
		headerOptionsStyle() {
			var style = {
				backgroundColor: this.getHeaderOptions.backgroundColor,
				backgroundPositionX: this.getHeaderOptions.backgroundPositionX + "px",
				backgroundPositionY: this.getHeaderOptions.backgroundPositionY + "px",
				backgroundSize: this.getHeaderOptions.backgroundSize + "%",
				fontFamily: this.getHeaderOptions.fontFamily
			}
			style.backgroundImage = (this.getHeaderOptions.backgroundImage.image_src) ? 'url("' + this.getHeaderOptions.backgroundImage.image_src + '")' : null
			return style
		},
		showSecondaryPagesMenu() {
			return true
		},
		myAccountLink() {
			if (this.window.width < 768) {
				return this.getUser.user_name ? '/myaccount' : '/myaccount/login'
			} else {
				return this.getUser.user_name ? '/myaccount/profile' : '/myaccount/login'
			}
		}
	},
	beforeDestroy() {
		clearInterval(this.newsAlertsInterval)
	},
	methods: {
		fetchTemplateCSS() {
			this.apiCall({ destination: 'get_item_template_css', data: { template_id: this.$route.query.template_id } }).then(obj => {
				if (obj.css) {
					let styleObject = document.createElement('style')
					styleObject.type = "text/css"
					styleObject.name = "testing"
					if (styleObject.styleSheet) {
						styleObject.styleSheet.cssText = obj.css
					} else {
						styleObject.appendChild(document.createTextNode(obj.css));
					}
					document.head.appendChild(styleObject)
					this.websiteMinimumAge = obj.WEBSITE_MINIMUM_AGE
					this.websiteMinimumAgeContent = obj.WEBSITE_MINIMUM_AGE_CONTENT
					if (this.websiteMinimumAge && !this.checkMinimumAgeConfirmed()) {
						this.clientLogo = obj.client_logo
						this.showAgeVerificationModal = true
					} else {
						this.browserIdSet = true
						this.fetchShoppingCartCount()
						this.fetchSiteTemplateData()
						this.fetchPreferences()
					}
				}
			}).catch(error => {
				console.log(error)
				// TO-DO: alert the user that the options couldn't be retrieved
			})
		},
		fetchSiteTemplateData() {
			this.updateSiteLoading({ value: 'get_frontend_template_data' })
			this.apiCall({ destination: 'get_frontend_template_data', data: { template_id: this.$route.query.template_id } }).then(obj => {
				this.updateSiteLoading({ value: 'get_frontend_template_data', remove: true })
				if (obj.status == 'error') {
					// TODO: alert the user that the template couldn't be retrieved
				} else if (obj.template_data.length == 0) {
					// TODO: alert user there is no template data?
				} else if (this.$route.name == "Home"){
					// obj.template_data.pageSections.forEach((item, i) => {
					//     this.updateSiteLoading({ value: item.name })
					// })
				}
				if (obj.client) {
					this.clientName = obj.client.company_name
					this.siteDescription = obj.client.site_description || this.clientName + ' is a premium sporting goods retailer'
					this.siteKeywords = obj.client.site_keywords || 'sporting goods, outdoor sports'
				}
				var titleElement = document.getElementById('website_title')
				if (this.$route.params.link_name) {
					titleElement.text = this.capitalizeWords(this.$route.params.link_name) + ' | ' + this.clientName
				} else {
					titleElement.text = 'Home | ' + this.clientName
				}
				document.querySelector('meta[name="description"]').setAttribute('content', this.siteDescription);
				document.querySelector('meta[name="keywords"]').setAttribute('content', this.siteKeywords);
			}).catch(error => {
				console.log(error)
				// TO-DO: alert the user that the options couldn't be retrieved
			})
			this.updateSiteLoading({ value: 'get_list_news_alerts' })
			this.apiCall({ destination: 'get_list_news_alerts' }).then(obj => {
				this.updateSiteLoading({ value: 'get_list_news_alerts', remove: true })
				if (obj.news_alerts) {
					this.newsAlerts = obj.news_alerts
				}
			}).catch(error => {
				console.log(error)
			})
			this.updateSiteLoading({ value: 'get_item_frontend_menu' })
			this.apiCall({ destination: 'get_item_frontend_menu', data: { menu_code: 'PAGES' } }).then(obj => {
				this.updateSiteLoading({ value: 'get_item_frontend_menu', remove: true })
				if (obj.menu_items) {
					this.pagesMenu = obj.menu_items
				}
			}).catch(error => {
				console.log(error)
			})
			this.updateSiteLoading({ value: 'get_item_frontend_menu' })
			this.apiCall({ destination: 'get_item_frontend_menu', data: { menu_code: 'PRODUCT_NAV' } }).then(obj => {
				this.updateSiteLoading({ value: 'get_item_frontend_menu', remove: true })
				this.productMenu = obj.menu_items
				if (obj.department_menu) {
					this.departmentMenu = obj.department_menu
				}
			}).catch(error => {
				console.log(error)
			})
			this.updateSiteLoading({ value: 'get_item_frontend_menu' })
			this.apiCall({ destination: 'get_item_frontend_menu', data: { menu_code: 'FOOTER' } }).then(obj => {
				this.updateSiteLoading({ value: 'get_item_frontend_menu', remove: true })
				if (obj.menu_items) {
					this.footerMenu = obj.menu_items
				}
			}).catch(error => {
				console.log(error)
			})
			this.fetchSocialMedia()
		},
		fetchPreferences() {
			let preferenceCodes = ['TAWKTO', 'MAILCHIMP_WEBSITE_CONNECTION', 'ANALYTICS_CODE', 'CREDOVA_STORE_ID','HIDE_WEBSITE_LOGIN']
			// is there a no-thanks cookie?
			if (!document.cookie.split(';').filter((item) => item.trim().startsWith('mc_no_thanks=')).length) {
				preferenceCodes.push('MAILCHIMP_SUBSCRIBE_LIST_ID')
				preferenceCodes.push('MAILCHIMP_DISPLAY_POPUP')
				preferenceCodes.push('MAILCHIMP_REQUIRE_NAME')
				preferenceCodes.push('MAILCHIMP_EXTRA_TEXT')
			}
			this.apiCall({ destination: 'get_item_preference', data: { preference_code: preferenceCodes } }).then(obj => {
				this.hideLoginLink = obj.HIDE_WEBSITE_LOGIN
				if (obj.TAWKTO) {
					let tawkToScript = document.createElement('script')
					tawkToScript.type = "text/javascript"
					let tawkToScriptContent = "var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();"
					tawkToScriptContent += "(function(){"
					tawkToScriptContent += 'var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];'
					tawkToScriptContent += 's1.async=true;'
					tawkToScriptContent += "s1.src='https://embed.tawk.to/" + obj.TAWKTO + "';"
					tawkToScriptContent += "s1.charset='UTF-8';"
					tawkToScriptContent += "s1.setAttribute('crossorigin','*');"
					tawkToScriptContent += "s0.parentNode.insertBefore(s1,s0);"
					tawkToScriptContent += "})();"
					tawkToScript.text = tawkToScriptContent
					document.head.appendChild(tawkToScript)
				}
				// Mailchimp...
				this.mailchimpSubscribeListId = obj.MAILCHIMP_SUBSCRIBE_LIST_ID
				this.mailchimpDisplayPopup = obj.MAILCHIMP_DISPLAY_POPUP
				if (this.mailchimpSubscribeListId && this.mailchimpDisplayPopup) {
					// is there a no-thanks cookie?
					if (document.cookie.split(';').filter((item) => item.trim().startsWith('mc_no_thanks=')).length) {
						this.mailchimpDisplayPopup = false
					} else {
						this.mailchimpExtraText = obj.MAILCHIMP_EXTRA_TEXT
						this.mailchimpRequireName = obj.MAILCHIMP_REQUIRE_NAME
						if (!this.showAgeVerificationModal) {
							this.showMailchimpPopup = true
						}
					}
				} else if (obj.MAILCHIMP_WEBSITE_CONNECTION) {
					let mailchimpScript = document.createElement('script')
					mailchimpScript.id = "mcjs"
					mailchimpScript.text = '!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","' + obj.MAILCHIMP_WEBSITE_CONNECTION + '");'
					document.head.appendChild(mailchimpScript)
				}
				// Credova...
				if (obj.CREDOVA_STORE_ID) {
					let credovaScript = document.createElement('script')
					credovaScript.src = "https://plugin.credova.com/plugin.min.js"
					document.body.appendChild(credovaScript)
					let credovaStoreIdScript = document.createElement('script')
					credovaStoreIdScript.text = "var CREDOVA_STORE_ID = '" + obj.CREDOVA_STORE_ID + "';"
					document.body.appendChild(credovaStoreIdScript)
				}
				// analytics...
				// GA4 new property stream
				if (!this.getIsDev) {
					let options = {
						config: { id: 'G-F18L0GJFGQ' }
					}
					if (obj.ANALYTICS_CODE) {
						options.includes = [{ id: obj.ANALYTICS_CODE, name: 'dealer' }]
					}
					setOptions(options)
					bootstrap().then((gtag) => {
						// done
					})
				}
			}).catch(err => {
				console.log(err)
			})
		},
		fetchSocialMedia() {
			// API can accept an array of preference codes
			let preferenceCodes = ['FACEBOOK', 'TWITTER', 'PINTREST', 'INSTAGRAM', 'YOUTUBE', 'BBB']
			this.apiCall({ destination: 'get_item_preference', data: { preference_code: preferenceCodes } }).then(obj => {
				for (let i = 0; i < preferenceCodes.length; i++) {
					if (obj[preferenceCodes[i]]) {
						this.socialMedia[preferenceCodes[i]] = obj[preferenceCodes[i]]
					}
				}
			}).catch(err => {
				console.log(err)
			})
		},
		menuLink(menuItem) {
			if (!menuItem.link_type || !menuItem.link_value) {
				return '/'
			} else if (menuItem.link_type == 'route') {
				return menuItem.link_value
			} else if (menuItem.link_type == 'myaccount' && menuItem.link_value.indexOf('membership') > -1 && !this.getUser.user_name) {
				return '/myaccount/login'
			} else if (menuItem.link_type == 'myaccount' && menuItem.link_value.indexOf('myaccount/') == 0) {
				return '/myaccount?section=' + menuItem.link_value.replace('myaccount/', '')
			} else if (menuItem.link_type == 'myaccount') {
				return '/myaccount?section=' + menuItem.link_value.replace(/^\/|\/$/g, '')
			} else {
				return (menuItem.link_type == 'page' || menuItem.link_type == 'home') ? '/' + menuItem.link_value : '/' + menuItem.link_type + '/' + menuItem.link_value
			}
		},
		updateOpenDropdown(state) {
			this.mobileDropdownOpen = false
			this.pagesDropdownOpen = false
			this.departmentDropdownOpen = false
			this.categoryDropdownOpen = false
		},
		doSearch() {
			if (this.searchTerm) {
				// already searched for this term?
				let path = '/search/' + encodeURIComponent(this.searchTerm)
				if (path == this.$route.path) { return }
				this.$router.push(path)
				setTimeout(() => {
					this.searchTerm = ''
					this.$refs.search_input.blur()
				}, 500);
			}
		},
		toggleDepartmentDropdown() {
			if (this.categoryDropdownOpen) {
				this.categoryDropdownOpen = false
			} else {
				this.departmentDropdownOpen = !this.departmentDropdownOpen
			}
		},
		getCategoryList(department) {
			this.apiCall({ destination: 'get_list_categories', data: { department_id: department.department_id } }).then(obj => {
				this.departmentDropdownOpen = false
				this.departmentName = department.description
				this.categoryMenu = obj.categories
				this.categoryDropdownOpen = true
			}).catch(error => {
				console.log(error)
			})
		},
		toggleCategoryDropdown() {
			this.categoryDropdownOpen = false
			this.departmentDropdownOpen = true
		},
		massageLink(link) {
			if (link.indexOf('http') != 0) {
				return 'https://' + link
			} else {
				return link
			}
		},
		capitalizeWords(rawString) {
			if (!rawString || typeof rawString !== 'string') return ''
			let string = rawString.toLowerCase()
			return string.replaceAll('-',' ').replace(/\b\w/g, l => l.toUpperCase())
		},
		goToCheckout() {
			window.location.href = '/checkout/review'
		},
		checkMinimumAgeConfirmed() {
			if (window.localStorage.getItem('minimum_age_confirmed')) {
				let confirmedDate = window.localStorage.getItem('minimum_age_confirmed')
				let now = new Date().getTime()
				let diff = now - confirmedDate
				if (diff < 86400000) {
					return true
				}
			}
			return false
		},
		confirmAge(confirmed) {
			// this.updateMimimumAgeConfirmed(confirmed)
			if (confirmed) {
				let now = new Date().getTime()
				window.localStorage.setItem('minimum_age_confirmed', now)
				this.fetchShoppingCartCount()
				this.fetchSiteTemplateData()
				this.fetchPreferences()
				this.browserIdSet = true
				this.showAgeVerificationModal = false
				if (this.mailchimpSubscribeListId && this.mailchimpDisplayPopup) {
					this.showMailchimpPopup = true
				}
			} else {
				window.localStorage.removeItem('minimum_age_confirmed')
				this.showAgeVerificationModal = false
				this.ageVerificationNotConfirmed = true
			}
		},
		subscribeNoThanks() {
			document.cookie = "mc_no_thanks=true; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/"
			this.showMailchimpPopup = false
		},
		subscribe() {
			if (this.saving) { return }
			this.mailchimpMessage = ''
			if (this.mailchimpRequireName && !this.mailchimpForm.full_name) {
				this.mailchimpMessage = 'Please enter your name.'
				return
			}
			if (!this.mailchimpForm.email_address) {
				this.mailchimpMessage = 'Please enter your email address.'
				return
			}
			this.saving = true
			this.mailchimpMessage = 'Subscribing...'
			this.apiCall({ destination: 'add_mailchimp_subscriber', data: this.mailchimpForm }).then(obj => {
				this.mailchimpMessage = obj.message
				if (obj.status == 'success') {
					setTimeout(() => {
						// set a cookie so the popup won't show again
						this.subscribeNoThanks()
					}, 2000)
				}
			}).catch(error => {
				console.log(error)
			})
		},
		handleResize() {
            this.window.width = window.innerWidth;
        }
	}
}
</script>

<style lang="scss">
@import 'frontend';

.bbb-seal {
  max-width: 150px;
  padding: 0 10px;
}

.age-verify-modal {
  .inner {
	max-width: 55em;
  }
  .content {
	max-height: none;
	font-size: 1.25em;
	padding: 1em 2em;
	font-weight: bold;
	text-align: center;
	@media (max-width: 768px) {
		font-size: 1em;
		overflow: scroll;
		max-height: 20em;
	}
  }
  .controls {
	font-size: 1.5em;
  }
}
.age-nope {
	h2 {
		background-color: red;
		color: #fff;
	}
}
.mailchimp-modal {
	label {
		display: block;
		margin-top: 0.5em;
	}
	input {
		font-size: 1.25em;
		padding: 0.25em;
		width: 100%;
	}
}
</style>
